 @font-face {
    font-family: "Maxima nouva";
    src: url("assets/fonts/MaximaNouva-Regular.otf") format("opentype"),
         url("assets/fonts/MaximaNouva-Bold.otf") format("opentype"),
         url("assets/fonts/MaximaNouva-SemiBold.otf") format("opentype"),
         url("assets/fonts/MaximaNouva-ExtraBold.otf") format("opentype"),
         url("assets/fonts/MaximaNouva-Italic.otf") format("opentype"),
         url("assets/fonts/MaximaNouva-BoldItalic.otf") format("opentype"),
         url("assets/fonts/MaximaNouva-ThinItalic.otf") format("opentype");
}

 body,
 html,
 #root {
     height: 100%;
     background-color: white !important;
     font-family: "Maxima nouva" !important;
 }
 
 body, html *
{
   font-family: "Maxima nouva" !important;
}

 .width-100 {
     width: 100% !important;
 }

 .width-50 {
     width: 50% !important;
 }

 .m-b-lg {
     margin-bottom: 15px !important;
 }

 .border-none {
     border: 0 none !important;
 }

 #taprr .taprr_custom__btn {
    text-align: left;
    text-transform: capitalize;
    min-height: 90px;
    margin-bottom: 5px;
    background-color: rgb(222 231 234);
    border: 1px solid rgba(105, 145, 214, .05);
    min-width: 300px;
    display: flex;
    justify-content: space-between;
 }

 #taprr .taprr_custom__btn svg {
    color: #fff;
 }

 #taprr .text__placeholder_color * {
     color: #fff !important;
 }

 .fanbies_input_placeholder div {
     margin-right: 1px;
 }

 .fanbies_input_placeholder div p,
 .fanbies_input_placeholder input {
     font-size: 1.2em;
     padding-left: 0;
 }

 #taprr input:disabled,
 #taprr .disabled_input {
     opacity: .5;
 }

 #taprr .page_landing_bg {
     /* background-color: #102437; */
     background-color: #ffffff !important;
 }

 .page_landing_bg,
 p,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 ul,
 li {
     font-family: "Maxima nouva" !important;
 }


 #taprr .page_landing_bg_v2 {
     background: rgb(240, 237, 242);
     background-color: linear-gradient(158deg, rgba(240, 237, 242, 1) 20%, rgba(238, 236, 236, 1) 71%, rgba(255, 255, 255, 1) 100%);
 }

 #taprr .landing_page_tab,
 #taprr .color-white {
     color: #ffffff !important;
 }

 #taprr .landing_page_tab.Mui-selected {
     border: 5px solid #ffffff;
     background-color: #102437;
     font-weight: 900;
 }

 .text-center {
     text-align: center;
 }

 .phone {
     box-sizing: border-box;
     border-radius: 40px;
     margin: 0 auto;
     width: 440px;
 }

 .phone__dashboard {
     height: 850px;
     border: 20px solid #6a4d4d36;
 }

 .phone_landing {
     position: absolute;
     transition: 0.5s;
     width: 440px;
     height: 860px;
 }

 .landing_mockup_container {
     transform: scale(0.55);
     transition: 0.5s;
     width: 450px;
 }

 .landing_mockup_container img:nth-child(1) {
     transform: translate(20px, -5px) skew(350deg);
     opacity: 0.8;
 }

 .landing_mockup_container img:nth-child(2) {
     transform: translate(-80px, -60px) skew(350deg);
     opacity: 1;
 }

 .phone_translate {
     transform: scale(0.56) translateX(0%) translateY(-30%) translate3d(0, 0, 0);
 }

 .unlock_overlay {
     position: absolute;
     width: 100%;
     height: 100%;
     background-color: #3d3c3cba !important;
     top: 0;
     left: 0;
     z-index: 999 !important;
     border-radius: 5px !important;
     cursor: pointer;
     backdrop-filter: blur(2px);
 }

 #taprr .link_btn {
     margin: 10px 0;
 }

 .datapicker>div {
     width: 100%;
 }

 #taprr .checkbox_formcontrol span {
     font-weight: normal;
 }

 #taprr .flatpickr-day.today {
     background-color: transparent;
 }

 .fanbies_input_custom_v1 input,
 #taprr .fanbies_input_custom_v1>div {
     font-size: 1.5em !important;
     padding: 0.8rem !important;
     height: 2em !important;
 }

 .fanbies_mk_input_v1 label {
     line-height: 2;
     font-size: .75em;
 }

 .fanbies_mk_input_v2 input {
     height: 43px;
 }

 .icon__paragraph {
     position: relative;
     margin: 0 5px;
 }

 .unlock_overlay .overlay__message {
     justify-content: center;
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
 }

 #taprr .dropShadow_logo {
     filter: drop-shadow(2px 2px 2px black);
 }

 #taprr .taprrContactTable tr {
     vertical-align: top;
 }


 .badge__element span {
     font-size: .6em !important;
     background: #3b4b5f !important;
     color: #ffffff !important;
 }

 .ripple {
     overflow: hidden;
 }

 .ripple:hover:before {
     animation: ripple 1s ease;
 }

 .ripple:before {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: #c5c3c31c;
     border-radius: 50%;
     transform: scale(0);
 }

 @keyframes ripple {
     from {
         transform: scale(0);
         opacity: 1;
     }

     to {
         transform: scale(3);
         opacity: 0;
     }
 }

 .admin__sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

 .admin__sidebar .side-bar__logo {
     width: 90%;
     margin: 0;
 }

 .side-bar__menu span {
    font-size: 14px;
  }

 .snackBar_container>div {
     background-color: rgb(77 110 128);
     color: #fff;
     font-weight: bold;
 }

 @media only screen and (min-width: 992px) {
    #taprr .taprr_custom__btn {
        margin: 10px 0;
    }
     .dash_tab_container {
         left: -15px;
     }
 }

 .inputSearch {
     border-radius: 20px;
     border: 1px solid #adb5bd;
     box-shadow: 24px;
     padding: 12px;
     margin-top: 15px;
     width: 100%;
 }

 .hidden {
     position: relative;
     display: none;
     visibility: hidden;
     text-indent: -99em;
     height: 1px;
 }

 .centerItem {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: row;
 }

 #taprr .fanbies_btn:disabled {
     visibility: hidden;
 }

 #taprr .mk_accordion {
     border-radius: 27px;
     border-style: solid;
     width: 100%;
 }

 #taprr .mk_accordion::before {
     background-color: transparent;
 }

 .mk_accordion_header>div {
     border-radius: 20px;
     min-width: 2rem;
     justify-content: center;
     margin: 0;
 }

 .mk_accordion_header>div>span {
     font-size: .75em;
     text-transform: capitalize;
 }

 #taprr .primary_color {
     color: #1d3e49;
 }


 #taprr .highlight_primary {
     color: #0F172A;
     padding: 0 8px 0 0;
 }

 #taprr .listItem {
     position: relative;
     margin-bottom: 10px;
     width: 30em;
 }

 .listItem__icon {
     position: relative;
     top: 5px;
     margin-right: 5px;
 }

 .maxWidth-md {
     max-width: 30em;
 }

 .maxWidth-sm {
     max-width: 25em;
 }

 .listItem__text span {
     font-weight: bold !important;
     font-size: 18px;
     color: #344767;
 }

 .listItem__text_sales span {
     font-size: 14px;
     color: #191919;
 }
 

 #taprr .mk_accordion_content {
     background-color: white;
     border-bottom-right-radius: 25px;
     border-bottom-left-radius: 25px;
 }

#taprr .mk_accordion_content_padding {
     padding: 0 0 5px 0;
 }

 #taprr .mk_accordion_content__menuItem {
     padding: 10px;
 }

 .light-lemo-green {
    background-color: #abcc30 !important;
 }

 .light-purple {
    background-color: #d6b5db !important;
 }

 .light-blue {
    background-color: #b2b7d9 !important;
 }

 .social__container {
     list-style: none;
     overflow: hidden;
 }

 .social__container li {
     float: left;
 }

 .product__image__accordion {
     display: flex;
     align-self: center;
     justify-content: center;
 }

 .mk_accordion__image_listing {
     margin-bottom: 20px;
     width: 100%;
 }

 .inputSearch:focus {
     outline: none !important;
     box-shadow: 0 0 10px #adb5bd;
 }

 #taprr .video__drop_play {
     position: absolute;
     left: 1px;
     color: #fff;
     right: 0;
     font-size: 2.5em !important;
     margin: 0 auto;
     top: 28%;
     z-index: 999;
     cursor: pointer;
 }

 #taprr .profile_links {
     width: 40%;
     max-width: 40%;
 }

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
     /* .dashboard_preview_panel {
    display: none !important;
  } */
     .phone_translate {
         transform: scale(0.75) translateX(0%) translateY(-15%) translate3d(0, 0, 0);
     }
     .phone_landing {
         position: relative;
         left: -10%;
     }

     #taprr .profile_links {
         width: 80%;
         max-width: 90%;
     }

     .landing_mockup_container img:nth-child(1) {
         transform: translate(0, -95px) skew(350deg);
     }

     .landing_mockup_container img:nth-child(2) {
         transform: translate(-86px, -1017px) skew(350deg);
     }

     .sponsorlogo {
         width: 30%;
     }

     #taprr .page_landing_bg {
         padding-bottom: 0;
     }

 }

 @media only screen and (min-width: 600px) {
     .landing_mockup_container_sm {
         display: none;
     }
     .preview.menuitems {
        display: none !important;
     }
 }

 .unblur {
     backdrop-filter: blur(30px);
 }

 .popover_container {
     background-color: #344767 !important;
     border-radius: 10px;
     padding: 10px;
 }

 #taprr .largeIcon {
     font-size: 1.5em !important;
 }

 #taprr .mediumIcon {
     font-size: 1em !important;
 }

 #taprr .smallIcon {
     font-size: .8em !important;
 }

 #taprr .xsmallIcon {
     font-size: .5em !important;
 }

 .list_item {
     border: 1px solid #c8c8c8;
     margin-bottom: 15px;
     border-radius: 10px;
     padding: 6px;
     cursor: pointer;
 }

 #taprr .display-none {
     display: none !important;
 }

 #taprr .display-block {
     display: block !important;
 }

 .btn-wrapper {
     display: flex;
     flex-flow: column wrap;
 }

 #taprr .mk_accordion_tool {
     box-shadow: none;
 }

 #taprr .mk_accordion_tool::before {
     height: 0;
 }

 #taprr .xlargeIcon {
     font-size: 2.5em !important;
 }

 #taprr .mediumIcon {
     font-size: 1.5em !important;
 }

 #taprr .marketplace_img_wrapper {
     cursor: pointer;
     display: flex;
     align-items: center;
     flex-direction: column;
 }

 #taprr .pos-absolute {
     position: absolute !important
 }

 #taprr .pos-relative {
     position: relative !important
 }

 .load-wraper {
     position: relative;
     width: 100%;
     background-color: rgb(211, 211, 211);
     z-index: 44;
     overflow: hidden;
     border-radius: 5px;
 }

 .load-wraper-medium {
     height: 200px;
 }

 .load-wraper-smaller {
     height: 150px;
 }

 .placeholder__icon {
     top: 40%;
     left: 38%;
     z-index: 999;
 }

 .placeholder__asset {
     top: 30%;
     z-index: 999;
     width: 100%;
 }



 .product__image_svg {
     top: -25px;
     right: 0;
 }

 .bold {
     font-weight: 900 !important;
 }

 .product__placeholder__text {
     left: 0;
     position: relative;
     font-size: 0.95em;
     font-weight: 600;
 }

 @-webkit-keyframes scroll {
     0% {
         transform: translateX(0);
     }
     100% {
         transform: translateX(calc(-250px * 7));
     }
 }

 @keyframes scroll {
     0% {
         transform: translateX(0);
     }
     100% {
         transform: translateX(calc(-250px * 7));
     }
 }

 .slider {
     background: white;
     margin: auto;
     overflow: hidden;
     position: relative;
     width: 100%;
 }

 .slider::before,
 .slider::after {
     position: absolute;
     width: 200px;
     z-index: 2;
 }

 .slider::after {
     right: 0;
     top: 0;
     transform: rotateZ(180deg);
 }

 .slider::before {
     left: 0;
     top: 0;
 }

 .slider .slide-track {
     -webkit-animation: scroll 30s linear infinite;
     animation: scroll 30s linear infinite;
     display: flex;
     width: calc(250px * 10);
 }

 .slider .slide {
     margin: 0 30px;
 }

 .activity {
     position: absolute;
     left: -45%;
     height: 100%;
     width: 45%;
     background-image: linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
     background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
     background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
     animation: loading 1s infinite;
     z-index: 45;
 }

 @keyframes loading {
     0% {
         left: -45%;
     }

     100% {
         left: 100%;
     }
 }

 #taprr .wallet_mk_accordion {
     border-radius: 0.75rem;
     border-color: transparent !important;
 }

 .dueEby {
     display: flex;
     -webkit-box-align: center;
     align-items: center;
     flex-direction: column;
     padding: 4rem;
     border-radius: 4px;
     text-align: center;
     background: rgb(21, 21, 21) !important;
     height: 320px;
     overflow-y: hidden;
 }
 .getpaid {
     background-size: cover;
     background-position: center;
     object-fit: cover;
     height: 100%;
     width: 100%;
     size: 100vw;
 }

 .advanced_option {
     transition: 0.5s ease-in-out 2s;
     overflow: hidden;
     width: 100%;
     height: 100%;
 }

 .shake-animation {
     animation: shake 1s cubic-bezier(.36, .07, .19, .97) both infinite;
     transform: translate3d(0, 0, 0);
     backface-visibility: hidden;
     perspective: 1000px;
 }

 .shake-animation-hover:hover {
     animation: shake 1s cubic-bezier(.36, .07, .19, .97) both infinite;
     transform: translate3d(0, 0, 0);
     backface-visibility: hidden;
     perspective: 1000px;
 }

 .swing-animation {
     animation: swing ease-in-out 1s infinite alternate;
 }

 .swing-animation-hover:hover {
     animation: swing ease-in-out 1s infinite alternate;
 }

 .rubberBand-animation {
     animation: RubberBand 3s infinite;
 }

 .rubberBand-animation-hover:hover {
     animation: RubberBand 3s infinite;
 }

 .pulse-animation:hover {
     transform: scale(1.03);
     opacity: .9 !important;
 }

 @keyframes shake {

     10%,
     90% {
         transform: translate3d(-1px, 0, 0);
     }

     20%,
     80% {
         transform: translate3d(2px, 0, 0);
     }

     30%,
     50%,
     70% {
         transform: translate3d(-4px, 0, 0);
     }

     40%,
     60% {
         transform: translate3d(4px, 0, 0);
     }
 }

 @keyframes swing {
     0% {
         transform: rotate(3deg);
     }

     100% {
         transform: rotate(-3deg);
     }
 }

 @keyframes RubberBand {
     0% {
         transform: scale3d(1, 1, 1);
     }
     35% {
         transform: scale3d(1.25, .75, 1);
     }
     45% {
         transform: scale3d(.75, 1, 1);
     }
     60% {
         transform: scale3d(1.2, .8, 1);
     }
     75% {
         transform: scale3d(1.05, .95, 1);
     }
     100% {
         transform: scale3d(1, 1, 1);
     }
 }

 .pulse-animation:hover {
     transform: scale(1.03);
 }


 .card {
     position: relative;
     width: 250px;
     height: 380px;
     background: #191919 !important;
     border-radius: 20px;
     overflow: hidden;
 }

 .card::before {
     content: "";
     position: absolute;
     top: -50%;
     width: 100%;
     height: 100%;
     background: #1d3e49;
     transform: skewY(345deg);
     transition: 0.5s;
 }

 .card:hover::before {
     top: -70%;
     transform: skewY(390deg);
 }

 .card::after {
     content: "";
     position: absolute;
     bottom: 0;
     left: 0;
     font-weight: 600;
     font-size: 6em;
     color: rgba(0, 0, 0, 0.1);
 }

 .card .imgBox {
     position: relative;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     padding-top: 20px;
     z-index: 1;
 }
 .card .contentBox {
     position: relative;
     padding: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     z-index: 2;
 }

 .card .contentBox .moreinfo {
     position: relative;
     top: -80px;
     opacity: 0;
     padding: 10px 30px;
     margin-top: 15px;
     color: #ffffff;
     text-decoration: none;
     border-radius: 10px;
     transition: 0.5s;
 }

 .card:hover .contentBox .moreinfo {
     top: 0;
     opacity: 1;
 }

 .mouse {
     height: 300px;
     width: auto;
 }

 .placeholder {
     max-height: 350px;
     background: #dddddd !important;
 }
 .faux-image-wrapper {
     margin-bottom: 10px;
     position: relative;
     height: 250px;
     padding-bottom: 10px;
 }
 .faux-image {
     background: #dddddd !important;
     border-radius: 4px;
     height: 100%;
     position: absolute;
     width: 100%;
 }
 .faux-text {
     background: #cacaca !important;
     border-radius: 4px;
     height: 20px;
     margin-bottom: 5px;
     padding-left: 10px;
 }

 .faux-text.short {
     width: 75%;
 }

 .shimmer {
     overflow: hidden;
     position: relative;
 }

 .shimmer::before {
     content: "";
     position: absolute;
     background: linear-gradient(90deg,
             rgba(255, 255, 255, 0) 0%,
             rgba(255, 255, 255, 0.4) 50%,
             rgba(255, 255, 255, 0) 100%);
     height: 100%;
     width: 100%;
     z-index: 1;
     animation: shimmer 1s infinite;
 }

 .pricing-card {
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     overflow: hidden;
     border-radius: 1.1rem;
     max-width: 300px;
     min-width: 280px;
     height: auto;
 }

 @keyframes shimmer {
     0% {
         transform: translateX(-100%);
     }
     100% {
         transform: translateX(100%);
     }
 }


 .p-a-none {
     padding: none !important;
 }

 .p-a-sm {
     padding: 5px !important;
 }

 .p-a-md {
     padding: 10px !important;
 }

 .p-a-lg {
     padding: 15px !important;
 }

 .thumbnail-box {
     cursor: pointer;
 }
 .thumbnail-box:hover {
     background-color: rgba(255, 255, 255, 0.4);
 }

 footer input {
     color: white !important;
 }

 footer input::placeholder {
     color: white !important;
 }

 .customColorSelect input {
     height: 40px;
 }